import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NS99CLZ'
}

export default function GTM(){
  return (
    TagManager.initialize(tagManagerArgs)
  );
}